import React from "react"
import Slider from "react-slick";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  arrows: false,
  // customPaging: function(i) {
  //   return (
  //     <span></span>
  //   );
  // },
  responsive: [
    {
      breakpoint: 980,
      settings: {
        variableWidth: false,
        centerMode: false
      }
    }
  ]
};
function splitStr(theStr){
    const res = theStr.split('</h2>');
    //return res[0]+"</h2>";
    const strippedString = res[0].replace(/(<([^>]+)>)/gi, "")
    if(strippedString.length > 70){
        return strippedString.split(" ").splice(0,10).join(" ")+"...";
    }
    else{
        return strippedString;
    }
    //return strippedString;

}


  function PageCards({ pageCardsGroup,pageCardsHeading }){
  return (
    <>
    <section className="content">
    <div className="eighty-spacer"></div>
        <div className="content-block">
        {pageCardsHeading ? (<h3 className="savings-title">{pageCardsHeading}</h3>) : null}
        </div>
        <div className="sixty-spacer"></div>

    <Slider className="slick-featured-card-container" {...slickSettings}>
      {pageCardsGroup.length
        ? pageCardsGroup.map((cards,index) => (
          <div>
          <a href={`/${cards.pageCard.uri}`} key={index} className="scaleDiv">
             <div className="full-width-flex-container slick-flex-mob">

                <div className="slick-featured-card-content-container">
                   <div className="slick-featured-card-content-holder">

                      <div className="slick-featured-card-title-holder">
                      {cards.pageCard.title ? (
                         <h2>
                            {cards.pageCard.title}</h2>
                             ) : null}
                      </div>

                      <div className="slick-featured-card-text-holder">
                      {cards.pageCard.content ? (
                            <h3 dangerouslySetInnerHTML={{ __html: splitStr(cards.pageCard.content  ) }}/>
                         ) : null}
                      </div>
                      <div className="slick-featured-card-link-holder">
                          <span>
                                Read more
                               </span>
                      </div>

                    </div>

                </div>
                {cards.pageCard.featuredImage ? (
                <div className="slick-featured-card-image-holder">
                        <img src={`/htbcontent/uploads/${cards.pageCard.featuredImage.mediaDetails.file}`} alt={cards.pageCard.featuredImage.altText} title={cards.pageCard.featuredImage.title}/>
                     </div>
                     ) : null}
                </div>
          </a>
          </div>
          ))
        : null}
        </Slider>
          <div className="eighty-spacer"></div>
          </section>
          </>

  )
  }


export default PageCards
